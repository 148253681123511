* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

@font-face {
  font-family: "MERegular";
  src: url("./fonts/MonumentExtended-Regular.otf") format("opentype");
}

@font-face {
  font-family: "BriGroBold";
  src: url("./fonts/BricolageGrotesque-Bold-BF648bd57888479.ttf") format("ttf");
}

@font-face {
  font-family: "NeuGroReg" for;
  src: url("./fonts/NeurialGrotesk-Regular.otf") format("opentype");
}

@font-face {
  font-family: "SeyOneReg";
  src: url("./fonts//SeymourOne-Regular.ttf") format("ttf");
}

.App {
  height: 100vh;
}

.meregular__font {
  font-family: "MERegular", sans-serif;
  font-weight: 300;
}

.brigro__font {
  font-family: "BriGroBold", sans-serif;
}

.neugro__font {
  font-family: "NeuGroReg", sans-serif;
  /* font-weight: 100; */
}

.seyone__font {
  font-family: "SeyOneReg", sans-serif;
}

body {
  background: linear-gradient(
    180deg,
    rgba(18, 17, 17, 0.8) 0%,
    rgba(34, 33, 33, 0.1) 49%,
    rgba(19, 18, 18, 0.88) 81.92%
  );
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 5px;
  background: linear-gradient(
    180deg,
    rgba(18, 17, 17, 0.9) 5%,
    rgba(34, 33, 33, 0.3) 50%,
    rgba(19, 18, 18, 0.88) 80%
  );
}

body::-webkit-scrollbar-thumb {
  height: 5px;
  border-radius: 100px;
  background: #ffb800;
  scroll-behavior: smooth;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* for firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.btnCta {
  border-radius: 20px;
  border: 1px solid #946d09;
  background: linear-gradient(180deg, #493503 0%, #8c6c1c 100%);
}

.primaryColorText {
  background: linear-gradient(0deg, #bd8b0b 0%, #cc9405 37.51%, #ffb800 79.81%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#bgimgA {
  background-image: linear-gradient(
      180deg,
      rgba(18, 17, 17, 0.8) 0%,
      rgba(34, 33, 33, 0.1) 49%,
      rgba(19, 18, 18, 0.88) 81.92%
    ),
    url("./Assets/bg-A.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  padding: 0 0 100px 0;
}

#bgimgB {
  background-image: linear-gradient(
      180deg,
      rgba(18, 17, 17, 0.8) 0%,
      rgba(34, 33, 33, 0.1) 49%,
      rgba(19, 18, 18, 0.88) 81.92%
    ),
    url("./Assets/bg-B.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  max-height: 100%;
  padding: 10px 0 50px 0;
}

#bgimgC,
#pandanomics {
  background-image: linear-gradient(
      180deg,
      rgba(18, 17, 17, 0.8) 0%,
      rgba(34, 33, 33, 0.1) 49%,
      rgba(19, 18, 18, 0.88) 81.92%
    ),
    url("./Assets/bg-C.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
}

#bgimgE {
  background-image: linear-gradient(
      180deg,
      rgba(18, 17, 17, 0.8) 0%,
      rgba(34, 33, 33, 0.1) 49%,
      rgba(19, 18, 18, 0.88) 81.92%
    ),
    url("./Assets/bg-e.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
}

#contact {
  background-image: linear-gradient(
      180deg,
      rgba(18, 17, 17, 0.8) 0%,
      rgba(34, 33, 33, 0.1) 49%,
      rgba(19, 18, 18, 0.88) 81.92%
    ),
    url("./Assets/bg-f.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
}

.btn {
  border-radius: 80px;
  border: 1px solid #fff;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(22px);
}

.blurBGG {
  border-radius: 40px;
  border: 1px solid #1bc3c3;
  background: rgba(18, 18, 18, 0.5);
  backdrop-filter: blur(17px);
}

.panda {
  border-radius: 13.176px;
  background: rgba(46, 46, 46, 0.9);
  box-shadow: 0px 3.313px 8.282px 0px rgba(0, 0, 0, 0.35),
    0px 2.635px 9.223px 0px rgba(139, 107, 25, 0.5) inset,
    0px 3.313px 36.441px 0px rgba(238, 238, 238, 0.35);
  backdrop-filter: blur(9.938556671142578px);
}

.darkColorBgShade {
  background: linear-gradient(
    180deg,
    rgba(18, 17, 17, 0.9) 5%,
    rgba(34, 33, 33, 0.3) 50%,
    rgba(19, 18, 18, 0.88) 80%
  );
}

#transition-menu {
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 0.2s, transform 1s;
}

#transition-menu.open {
  animation: drop 0.2s forwards;
}

#transition-menu.close {
  animation: rise 0.2s forwards;
}

@keyframes drop {
  0% {
    transform: translateY(-100%);
    opacity: 0;
    animation-timing-function: ease-out;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
    animation-timing-function: ease-in;
  }
}

@keyframes rise {
  0% {
    transform: translateY(0);
    opacity: 1;
    animation-timing-function: ease-out;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
    animation-timing-function: ease-in;
  }
}

.bx-shadow {
  box-shadow: 0px 0px 14px 0px rgba(229, 229, 229, 0.25);
}

#menu::-webkit-scrollbar {
  display: none;
  scroll-behavior: smooth;
}

.backdropp {
  backdrop-filter: blur(17px);
}

#popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

#popup-content {
  background-color: #000;
  background-image: url("./Assets/bg-popup.png");
  background-position: center;
  background-repeat: no-repeat;
  height: auto;
  /* padding: 20px; */
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  margin: 20px 0;
}

#popup-content::before {
  background: black;
}
#popup-content::after {
  background: black;
}

#close-btn {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.text-design {
  background: linear-gradient(192deg, #fff -17.51%, #999 97.14%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
